<template>
  <div class="white-block-content text-center">
    <SsrHead :meta="meta" />
    <i
      class="fa-exclamation-circle"
      style="font-size: 150px;"
      aria-hidden="true"
    ></i>
    <h1>{{ t("router_error.error") }}</h1>
    <router-link to="/">{{ t("router_error.home") }}</router-link>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useMeta } from "vue-meta";

import SsrHead from "../components/SsrHead";

export default {
  name: "RouterError",
  components: {
    SsrHead,
  },
  setup() {
    const meta = {
      meta: [{ name: "robots", content: "noindex,nofollow" }],
    };
    useMeta(meta);

    const { t } = useI18n();
    return {
      t,
      meta,
    };
  },
};
</script>
